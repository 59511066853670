<template>
  <div>
    <portal to="sticky-component" tag="div" name="sticky-component">
      <section class="video-section">
        <video autoplay muted playsinline loop preload="auto">
          <source src="@/assets/images/about/PC_about_time_lapse.webm" type="video/webm" />
        </video>
        <div class="txt-wrap">
          <div class="txt1-cont">
            <h2>
              <span>
                멈추지 않는 고민은<br />
                언제나 새로운 길을<br />
                걷게 합니다.
              </span>
            </h2>
          </div>
          <div class="txt2-cont">
            <h2>
              <span>
                MAKE GREATNESS
                <span class="txt2">
                  우리는 늘 경계를 허물고 확장시키며<br />
                  새로운 경험을 만들어 냅니다.
                </span>
              </span>
            </h2>
          </div>
        </div>
        <div class="trigger"></div>
      </section>
      <div>
        <tab-item :list="tab.list" :name="tab.name" v-model="tab.active" />
      </div>
    </portal>
    <div style="height: calc(200vh + 152px)"></div>
    <div class="tab-contents">
      <!-- //introduction -->
      <template v-if="tab.active === 'introduction'">
        <div class="introduction">
          <div class="group group-01 contents">
            <p class="desc">
              업력 28년, 수많은 경험들 속에서 축적된 노하우를 바탕으로<br />
              브랜드의 가치를 높이는 솔루션을 제공합니다.
            </p>
            <h2 class="section-tit">
              끊임없이 고민하고 분석하며<br />
              고객의 성공적인 비즈니스를 실현하는,<br />
              NO.1 디지털 에이전시입니다.
            </h2>
          </div>
          <div class="lottie-cont lottie01">
            <lottie-about01 />
          </div>
          <!--          <div class="video">-->
          <!--            <div class="video-title">-->
          <!--              <p>Great Performance</p>-->
          <!--              <p>Great Opportunity</p>-->
          <!--              <p>Great People</p>-->
          <!--              <p>Great Culture</p>-->
          <!--            </div>-->
          <!--            <div class="video-bg">-->
          <!--              <video autoplay muted playsinline loop preload="auto">-->
          <!--                <source src="@/assets/images/about/PC_about_time_lapse.mp4" type="video/mp4" />-->
          <!--              </video>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="contents">
            <div class="group group-02">
              <h2 class="section-tit">
                누구보다 역동적으로 나아가며<br />
                세상을 놀라게 하는<br />
                위대한 결과물을 만들어 냅니다.
              </h2>
              <ul class="list">
                <li>
                  <strong>Radical Thinking</strong>
                  <p class="desc">
                    다양한 현상과 이슈에 대해 극단적으로 질문하며<br />
                    문제가 해결될 때까지 분석과 검증을 반복하여<br />
                    논리적인 설계 과정을 만들어갑니다.
                  </p>
                </li>
                <li>
                  <strong>Geek Working</strong>
                  <p class="desc">
                    작은 요소에도 집착하여 우리만의 차이를 만들어가며<br />
                    프로젝트의 시작과 끝을 체계적으로 정의합니다.
                  </p>
                </li>
              </ul>
              <div class="lottie-cont lottie-02">
                <lottie-about02 />
              </div>
            </div>
            <div class="group group-03">
              <h2 class="section-tit">
                상장사의 안전성과<br />
                우수한 기술력을 기반으로<br />
                대한민국 WEB 3.0의 선두를<br />
                이끌어 갑니다.
              </h2>
              <ul class="list">
                <li>
                  <strong>Digital Experts</strong>
                  <p class="desc">
                    <span>UX 컨설팅 / 구축 / 운영</span>
                    UX 컨설팅부터 기획, 디자인, WEB/APP 개발<br />
                    및 운영까지 이르는 A to Z 프로세스를 제공합니다.
                  </p>
                </li>
                <li>
                  <strong>Blockchain Experts</strong>
                  <p class="desc">
                    <span>메타버스 / NFT / 블록체인</span>
                    기업의 WEB 3.0 프로젝트에 대해 NFT&메타버스<br />
                    생태계를 구축하는 전문적인 파트너십을 제공합니다.
                  </p>
                </li>
                <li>
                  <strong>AD Tech Experts</strong>
                  <p class="desc">
                    <span>메타 포인트 / 캐시 팩토리</span>
                    메타버스 시대에 맞는 새로운 개념의 리워드 플랫폼을 통해<br />
                    시장에 없던 새로운 경험과 서비스를 제공합니다.
                  </p>
                </li>
              </ul>
              <div class="lottie-cont lottie-03">
                <lottie-about03 />
              </div>
            </div>
            <div class="group group-04">
              <h2 class="section-tit">
                우리는 사람을 향한<br />
                따뜻한 감성과 이성적 사고를<br />
                겸비한 디지털 전문가 집단입니다.
              </h2>
              <div class="desc">
                다양한 실전 경험과 지식을 갖춘 전문가들이 당신의 사업 파트너가 되어<br />
                높은 수준의 구축과 안정적인 운영 서비스를 제공합니다.
              </div>
              <ul class="figure-list">
                <li>
                  <strong>1,300</strong>
                  <span>프로젝트 수행 경험</span>
                </li>
                <li>
                  <strong>15<em>Years</em></strong>
                  <span>PM 평균 경력</span>
                </li>
                <li>
                  <strong>140</strong>
                  <span>전문가 수</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="images">
            <div class="top image">
              <img :src="require('@/assets/images/about/img_about_01.png')" alt="CEO" />
              <p>왼쪽부터 대표이사 강승진, 대표이사 최복규</p>
            </div>
            <div class="bottom">
              <div class="image">
                <img :src="require('@/assets/images/about/img_02.webp')" alt="awards" />
              </div>
              <div class="image">
                <img :src="require('@/assets/images/about/img_about_03.webp')" alt="works" />
              </div>
            </div>
          </div>
          <div class="group group-05 contents">
            <h2 class="section-tit">
              이모션글로벌과 함께라면<br />
              그 어떤 것도 할 수 있고,<br />
              어떤 것이라도 될 수 있습니다.
            </h2>
            <div class="desc">
              다양한 크리에이터들과 경계 없는 협업을 통해 지속 가능한 기회를 만들어 냅니다.<br />
              우리의 입체적인 시선과 경험으로 새로운 인사이트를 제시합니다.
            </div>
            <div class="lottie-cont lottie-05">
              <lottie-about04 />
            </div>
          </div>
          <div class="group group-06 contents">
            <h2 class="section-tit">
              사고의 폭과 깊이를 확장시키는<br />
              수평적이고 자유로운 분위기 속에서<br />
              함께 성장합니다.
            </h2>
            <div class="desc">
              우리는 각자의 개성과 관점을 존중하고 서로 다름을 이해하며<br />
              공동의 목표를 향해 나아갑니다. 동료를 향한 신뢰는 유연한 팀 문화를 만들고<br />
              그 속에서 몰입을 통한 폭발적인 성장을 경험하게 됩니다.
            </div>
          </div>
          <div class="list-wrap" ref="listWrap" :style="{height: `${listWidth}px`}">
            <div class="list-scroll" ref="listScroll">
              <div class="list" ref="list">
                <ul ref="listUl" :style="{width: `${listWidth}px`}">
                  <li>
                    <img :src="require('@/assets/images/about/02_img_01.webp')" alt="업무이미지" />
                  </li>
                  <li>
                    <img :src="require('@/assets/images/about/02_img_02.webp')" alt="업무이미지" />
                  </li>
                  <li>
                    <img :src="require('@/assets/images/about/02_img_03.webp')" alt="업무이미지" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- introduction// -->

      <!-- //history -->
      <template v-else-if="tab.active === 'history'">
        <div class="award">
          <h2 class="section-tit gray">
            다년간의 경험으로 생긴 역량을 바탕으로<br />
            <em>우리는 멈추지 않고 계속해서 나아갑니다.</em>
          </h2>
          <div class="award-list" v-for="(awardYear, YearIndex) in awardList" :key="YearIndex">
            <h3 class="year-title">{{ awardYear.year }}</h3>
            <div class="recent" v-if="YearIndex === 0">
              <div class="recent-swiper-list" v-swiper:mySwiper="swiperOptions">
                <ul class="swiper-wrapper swiper-area">
                  <li
                    class="swiper-slide swiper-item"
                    v-for="(item, index) in awardYear.totalList[0].list"
                    :key="index"
                  >
                    <div class="title" v-html="handleNewLine(item.imgTitle)" />
                    <div class="thumb">
                      <img :src="item.imageFileName" :alt="item.title" />
                    </div>
                  </li>
                </ul>
                <div class="swiper-pagination" slot="pagination"></div>
              </div>
            </div>
            <div class="previous" v-else>
              <div class="previous-swiper" v-swiper:[`mySwiper${YearIndex}`]="swiperOptions2">
                <ul class="swiper-wrapper swiper-area">
                  <li
                    class="swiper-slide swiper-item"
                    v-for="(totalItem, totalListIndex) in awardYear.totalList"
                    :key="totalListIndex"
                  >
                    <h4 class="title" :class="{active: totalItem.list.some(el => el.active)}">
                      {{ totalItem.year }}
                    </h4>
                    <ul class="list">
                      <li
                        v-for="(item, index2) in totalItem.list"
                        :key="index2"
                        :class="{active: item.active}"
                        v-html="handleNewLine(item.contents)"
                      ></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- history// -->

      <!-- //Award -->
      <template v-else>
        <div class="contents">
          <div class="section">
            <h2 class="section-tit gray">
              이모션글로벌은 전문가들의 각기 다른 재능으로<br />
              <em>크리에이티브한 결과물을 도출해냅니다.</em>
            </h2>
          </div>
        </div>
        <ul class="work-list">
          <li v-for="item in prizeList" :key="item.id">
            <router-link :to="`/works/${item.id}`">
              <div class="thumb">
                <img :src="imgUrl(item.pcSmallImagePhysicalName)" alt="" />
              </div>
              <div class="info">
                <i class="circle" />
                <div class="title">
                  <div class="name">{{ item.projectServiceName }}</div>
                  <div class="prize" v-for="(prize, index) in item.prizeList" :key="index">
                    {{ prize.prize }}
                  </div>
                </div>
                <span class="more">Read More</span>
              </div>
            </router-link>
          </li>
        </ul>
      </template>
      <!-- Award// -->
    </div>
  </div>
</template>
<script>
  import {TweenMax, Power0, Power2, TimelineMax} from "gsap";
  import {mapGetters} from "vuex";
  import {apiPrizeList} from "@/api";
  import {commonMethods} from "@/utils/common-methods";
  import LottieAbout01 from "@/components/lottie/about01";
  import LottieAbout02 from "@/components/lottie/about02";
  import LottieAbout03 from "@/components/lottie/about03";
  import LottieAbout04 from "@/components/lottie/about04";
  export default {
    name: "About",
    components: {LottieAbout01, LottieAbout02, LottieAbout03, LottieAbout04},
    metaInfo() {
      return {
        title: "About",
        titleTemplate: "%s | e · motion",
        meta: [
          {
            property: "og:title",
            content: "About | e · motion",
            vmid: "og:title"
          },
          {
            property: "og:url",
            content: "https://www.emotion.co.kr/about/",
            vmid: "og:url"
          }
        ]
      };
    },
    data() {
      return {
        tab: {
          name: "tab",
          list: [
            {
              id: "introduction",
              title: "Introduction"
            },
            {
              id: "history",
              title: "History"
            },
            {
              id: "award",
              title: "Award"
            }
          ],
          active: "introduction"
        },
        listWidth: 0,
        scene: null,
        page: 0,
        size: 6,
        loading: false,
        prizeList: [],
        swiperOptions: {
          autoplay: {delay: 5000},
          speed: 700,
          loop: true,
          effect: "fade",
          fadeEffect: {
            crossFade: true
          },
          pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,

            renderBullet: (index, currentClass) => {
              return `
              <div class="${currentClass}">
                <span class="title">${this.awardList[0].totalList[0].list[index].title}</span>
                <span class="text">${this.awardList[0].totalList[0].list[index].contents}</span>
              </div>`;
            }
          }
        },
        swiperOptions2: {
          slidesPerView: "auto",
          spaceBetween: 10
        },
        awardList: [
          {
            year: 2023,
            totalList: [
              {
                year: 2023,
                list: [
                  {
                    title: "핑거버스",
                    contents: "웹어워드코리아 서비스 생활서비스 부문 통합대상​",
                    imgTitle: "<span class='black'>Web Award<br>Korea 2022<br>서비스<br>생활서비스<br>통합 대상",
                    imageFileName: require("@/assets/images/about/img_award2023_06.png")
                  },
                  // {
                  //   title: "마더브레인 ",
                  //   contents: "웹어워드코리아 생활서비스 부문 대상",
                  //   imgTitle: "<span>Web Award<br>Korea 2022<br>소재1부품<br>대상",
                  //   imageFileName: require("@/assets/images/about/img_award2022_03.webp")
                  // },
                  {
                    title: "와이넵",
                    contents: "앱어워드2023 식음료 분야 대상",
                    imgTitle: "<span>App Award<br>Korea 2023<br>식음료<br>대상",
                    imageFileName: require("@/assets/images/about/img_award2023_03.png")
                  },
                  {
                    title: "삼성전자",
                    contents: "앱어워드 모바일마케팅 대상​",
                    imgTitle:
                        "<span class='black'>Web Award<br/>Korea 2023<br/>마케팅 분야<br/>대상",
                    imageFileName: require("@/assets/images/about/img_award2023_04.png")
                  },
                  {
                    title: "아난티",
                    contents: "웹어워드2023 모바일UI/UX 이노베이션 대상",
                    imgTitle: "<span class='black'>Web Award<br>Korea 2023<br>UI/UX 분야<br>대상",
                    imageFileName: require("@/assets/images/about/img_award2023_05.png")
                  },
                  {
                    title: "핑거버스",
                    contents: "&어워드 생활편의서비스의분야 위너 등 3관왕 수상​",
                    imgTitle:
                        "<span class='black'>& Award <br>생활편의<br>서비스분야<br>위너 3관왕",
                    imageFileName: require("@/assets/images/about/img_award2023_01.png")
                  }
                ]
              }
            ]
          },
          {
            year: 2020,
            totalList: [
              {
                year: 2022,
                list: [
                  {
                    contents: "T 다이렉트샵<br/>웹어워드2022 서비스 이노베이션 대상"
                  },
                  {
                    contents: "LG이노텍<br/>웹어워드2022 소재1부품 분야 대상"
                  },
                  {
                    contents: "JAJU<br/>앱어워드2022 학생교육 분야 대상"
                  },
                  {
                    contents: "이베스트온<br/>앱어워드2022 증권 분야 최우수상"
                  }
                ]
              },
              {
                year: 2021,
                list: [
                  {
                    contents:
                      "코크플레이<br/>웹어워드2021 모바일 브랜드 이노베이션 대상<br/>앱어워드2021 브랜드 부문 통합대상"
                  },
                  {
                    contents: "종가 글로벌<br/>웹어워드2021 마케팅 이노베이션 대상"
                  },
                  {
                    contents:
                      "JAJU<br/>웹어워드2021 브랜드 쇼핑몰 분야 최우수상<br/>앱어워드2021 브랜드 쇼핑 분야 대상"
                  }
                ]
              },
              {
                year: 2020,
                list: [
                  {
                    contents:
                      "코크플레이<br/>앱어워드 2020 브랜드부문 통합대상<br/>&어워드 식음료 분야 Winner"
                  },
                  {
                    contents:
                      "신비아파트<br/>앱어워드 2020 교육/문화부문 통합대상<br/>&어워드 엔터테인먼트 분야 Grand Prix"
                  },
                  {
                    contents: "아성다이소<br/>웹어워드 2020 생활프랜차이즈 분야 대상"
                  }
                ]
              }
            ]
          },
          {
            year: 2010,
            totalList: [
              {
                year: 2019,
                list: [
                  {
                    contents: "FSN 사옥 이전"
                  },
                  {
                    contents: "CJ제일제당 CJ THE MARKET"
                  },
                  {
                    contents: "웹어워드2019 쇼핑몰부문 대상 수상\n&어워드  GRAND PRIX 수상"
                  }
                ]
              },
              {
                year: 2018,
                list: [
                  {
                    contents: "FSN 합류",
                    active: true
                  },
                  {
                    contents: "애경산업 FFLOW"
                  },
                  {
                    contents: "웹어워드2018 쇼핑몰부문 대상 수상"
                  }
                ]
              },
              {
                year: 2017,
                list: [
                  {
                    contents: "SK텔레콤 T전화 iF 디자인어워드 수상",
                    active: true
                  },
                  {
                    contents: "LG전자 디자인 협력업체 선정"
                  },
                  {
                    contents: "YDM  buzz 통합"
                  }
                ]
              },
              {
                year: 2016,
                list: [
                  {
                    contents: "신사옥 이전(신사동)"
                  }
                ]
              },
              {
                year: 2014,
                list: [
                  {
                    contents: "(주)옐로모바일 합류",
                    active: true
                  },
                  {
                    contents: "기업부설연구소 설립"
                  },
                  {
                    contents: "(주)옐로디지털마케팅(YDM) 합류"
                  }
                ]
              },
              {
                year: 2013,
                list: [
                  {
                    contents: "장애인차별금지법을 위한 웹 접근성 출범 "
                  },
                  {
                    contents: "디지털마케팅그룹 출범 "
                  }
                ]
              },
              {
                year: 2011,
                list: [
                  {
                    contents: "크리에이티브 컨설팅을 위한 UX그룹 출범"
                  }
                ]
              },
              {
                year: 2010,
                list: [
                  {
                    contents: "국내최초위치기반모바일SNS ‘1km’ 출시"
                  },
                  {
                    contents: "웹어워드2010 최고대상 수상 (CJ그룹)"
                  }
                ]
              }
            ]
          },
          {
            year: 2000,
            totalList: [
              {
                year: 2008,
                list: [
                  {
                    contents: "미국 LA 현지법인 설립  e·motion CA, Inc."
                  },
                  {
                    contents: "미국 현대자동차 홈페이지 구축 및 \n현지 운영 (2008~2011년)"
                  }
                ]
              },
              {
                year: 2006,
                list: [
                  {
                    contents: "벤처기업지정 (기술평가보증기업)"
                  }
                ]
              },
              {
                year: 2005,
                list: [
                  {
                    contents: "일본 동경 현지법인 설립 株式會社 e·motion",
                    active: true
                  }
                ]
              },
              {
                year: 2004,
                list: [
                  {
                    contents: "대한민국 초고속성장 기술기업 50 우수상\n(Deloitte & Touche)"
                  },
                  {
                    contents: "대한민국 벤쳐기업대상 국무총리상 수상"
                  },
                  {
                    contents:
                      "미국 뉴욕페스티벌 Interactive Awards\nBest Website Design (Nike.co.kr)"
                  }
                ]
              },

              {
                year: 2003,
                list: [
                  {
                    contents: "대한민국 초고속성장 기술기업 50 우수상 장\n(Deloitte & Touche)"
                  },
                  {
                    contents: "대한민국 벤쳐기업대상 국무총리상 수상"
                  }
                ]
              },
              {
                year: 2002,
                list: [
                  {
                    contents:
                      "코스닥(KOSDAQ) 등록 및 최연소CEO기록\n(2002.08) 정주형, 만28세 크리에이티브"
                  }
                ]
              },
              {
                year: 2001,
                list: [
                  {
                    contents:
                      "한국일보와 대학교수 전문가그룹이 선정한\n21세기를 대표할 21개 IT기업 선정",
                    active: true
                  }
                ]
              },
              {
                year: 2000,
                list: [
                  {
                    contents: "Icti, VoIP 전문기업 ‘신양정보통신’ 인수"
                  },
                  {
                    contents: "전경련 선정 우수벤처기업 선정\n(총60개 벤쳐기업 중 2위) "
                  }
                ]
              }
            ]
          },
          {
            year: 1990,
            totalList: [
              {
                year: 1997,
                list: [
                  {
                    contents:
                      "Web programing SW ‘CGI Builder’ 개발  및\n신 SW상품대상 수상 (정보통신부)"
                  }
                ]
              },
              {
                year: 1998,
                list: [
                  {
                    contents:
                      "대한민국 경찰청 홈페이지 구축 및\n인터넷 대상 정부부문 1위 수상 (Yahoo)"
                  }
                ]
              },
              {
                year: 1995,
                list: [
                  {
                    contents: "e·motion 창립 (1995.12)",
                    active: true
                  }
                ]
              }
            ]
          }
        ]
      };
    },
    computed: {
      ...mapGetters(["GE_CONT_HEIGHT"])
    },
    watch: {
      $route() {
        if (this.$route.query["tab"]) {
          let y = window.scrollY;
          if (document.querySelector(".tab-contents").offsetTop < y) {
            const offset = document.querySelector(".tab-contents").offsetTop + 1;
            this.$nextTick(() => {
              setTimeout(() => {
                window.scrollTo({top: offset});
              }, 0);
            });
          } else {
            this.$nextTick(() => {
              setTimeout(() => {
                window.scrollTo(0, y);
              }, 0);
            });
          }
        }
      },
      GE_CONT_HEIGHT() {
        if (this.$el.offsetParent) {
          this.init();
        }
      },
      "tab.active"(val1) {
        if (val1 === "3") {
          this.$nextTick(() => {
            this.awardAni();
          });
        }
      }
    },
    activated() {
      this.fetchData();
      this.init();
      this.getListWidth();
    },
    mixins: [commonMethods],
    methods: {
      videoAni() {
        this.scene?.destroy(true);
        const tw1 = TweenMax.fromTo(
          document.querySelector(".txt1-cont h2"),
          0.35,
          {
            y: 120,
            opacity: 1
          },
          {
            y: 0,
            opacity: 0,
            ease: Power2.easeInOut
          }
        );

        const tw2 = TweenMax.fromTo(
          document.querySelector(".txt2-cont h2"),
          0.35,
          {
            y: 240,
            opacity: 0
          },
          {
            y: 120,
            opacity: 1,
            ease: Power2.easeInOut
          }
        );
        const tween0 = this.$scrollmagic
          .scene({
            offset: 0,
            triggerElement: document.querySelector(".trigger"),
            triggerHook: 0
          })
          .setTween([tw1, tw2]);
        this.$scrollmagic.addScene(tween0);
      },
      awardAni() {
        document.querySelectorAll(".section, .work-list li").forEach(el => {
          const tw = TweenMax.fromTo(
            el,
            0.5,
            {
              y: 100
            },
            {
              y: 0,
              ease: Power0.easeInOut
            }
          );
          const sc = this.$scrollmagic
            .scene({
              offset: 0,
              triggerElement: el,
              triggerHook: 1,
              duration: 500
            })
            .setTween(tw);
          this.$scrollmagic.addScene(sc);
        });
      },
      getListWidth() {
        this.listWidth = 0;
        if (this.$refs.listUl) {
          this.$refs.listUl.querySelectorAll("li").forEach(el => {
            this.listWidth =
              this.listWidth + el.clientWidth + parseInt(window.getComputedStyle(el).marginLeft);
          });
          this.pageAnimation();
        }
      },
      pageAnimation() {
        this.scene?.destroy(true);

        const timeline = new TimelineMax();
        const item = this.$refs.listWrap;
        const inner = this.$refs.listScroll;
        const tween1 = TweenMax.to(inner, 1, {
          ease: Power0.easeInOut,
          y: this.listWidth - inner.clientHeight
        });
        const item2 = this.$refs.list;
        const inner2 = this.$refs.listUl;
        const tween2 = TweenMax.to(inner2, 1, {
          ease: Power0.easeInOut,
          x: item2.clientWidth - this.listWidth
        });
        timeline.add([tween1, tween2]);
        this.scene = this.$scrollmagic
          .scene({
            triggerElement: item,
            triggerHook: 0.1,
            duration: Math.max(this.listWidth - inner.clientHeight, 0) || 0
          })
          .setTween(timeline);
        window.removeEventListener("scroll", this.getListWidth);
        window.addEventListener("scroll", this.getListWidth);
        this.$scrollmagic.addScene(this.scene);
        setTimeout(() => {
          this.loading = false;
        }, 100);
      },
      tabFixed() {
        const item = document.querySelector(".tab-top");
        const contents = document.querySelector(".tab-contents");
        if (item.style.position === "fixed") return;
        const scene1 = this.$scrollmagic
          .scene({
            triggerElement: item,
            triggerHook: 0,
            duration: contents.clientHeight - "200vh" || 0
          })
          .setPin(item)
          .setClassToggle(".tab-top", "flex-end");
        this.$scrollmagic.addScene(scene1);
      },
      init() {
        this.videoAni();
        this.tabFixed();
      },
      async fetchData() {
        this.loading = true;
        try {
          const {
            data: {data: response}
          } = await apiPrizeList({
            page: this.page
            //size: this.size
          });
          this.prizeList = this.prizeList.concat(response.content);
          console.log(this.prizeList);
        } catch (error) {
          console.error(error.response);
        }
      }
    },
    created() {
      this.fetchData();
      this.$nextTick(() => {
        this.getListWidth();
      });
    },
    destroyed() {
      this.scene?.destroy(true);
    }
  };
</script>
<style lang="scss" scoped>
  ::v-deep .tab-top {
    .tab-list {
      ul {
        width: 503px;
      }
    }
  }
  .video-section {
    position: relative;
    width: 100%;
    min-width: 1264px;
    height: 200vh;
    z-index: 99;
    background: black;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
      z-index: 1;
    }
    video {
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      object-fit: cover;
      z-index: 1;
    }
    .trigger {
      position: absolute;
      left: 0;
      top: 30vh;
      width: 100%;
      height: 100vh;
    }
    .txt-wrap {
      position: sticky;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100vh;
      z-index: 2;
      > div {
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        h2 {
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          font-weight: 700;
          font-size: 70px;
          line-height: 100px;
          color: #fff;
          height: calc(100vh - 120px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          & > span {
            display: block;
            text-align: left;
            width: 1224px;
            margin: 0 auto;
            height: 540px;
          }
        }
      }
      .txt2-cont {
        h2 {
          @include montserrat();
          .txt2 {
            display: block;
            margin-top: 20px;
            font-weight: 400;
            font-size: 30px;
            line-height: 50px;
            font-family: "Noto Sans KR", sans-serif;
          }
        }
      }
    }
  }
  .list-wrap {
    height: auto;
    width: 100%;
    min-width: $globalWidth;
    overflow: hidden;
  }
  .list-scroll {
    @include globalWidth();
    padding-top: 150px;
    ul {
      display: flex;
      width: auto;
      li {
        overflow: hidden;
        flex: 0 0 auto;
        width: 1224px;
        height: 612px;
        &:hover,
        &:focus {
          .list-thumb {
            img {
              transform: scale(1.1);
            }
          }
          .list-cont {
            &-more {
              color: #ee2c3c;
              &::before {
                background: #ee2c3c;
              }
            }
          }
        }
      }
      li + li {
        margin-left: 10px;
      }
    }
  }
  .tab-contents {
    padding: 150px 0 160px;
  }
  .key-visual {
    position: relative;
    min-height: 1000px;
    height: 100vh;
    min-width: $globalWidth;
    .mask {
      height: 100vh;
      position: relative;
      overflow: hidden;
    }
    .contents {
      z-index: 2;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: center;
      align-items: flex-start;
      height: 100vh;
      min-height: 800px;
      position: relative;
    }
    .bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url(../../assets/images/about/about_visual.jpg) center center / cover no-repeat;
    }
    .txt {
      position: absolute;
      left: 0;
      z-index: 1;
      font-weight: 700;
      font-size: 70px;
      line-height: 100px;
      letter-spacing: -0.03em;
    }
  }
  .section {
    &-tit {
      font-weight: 700;
      font-size: 48px;
      line-height: 74px;
      color: #000;
      letter-spacing: -0.05em;
      &.gray {
        color: #a0a0a0;
      }
      em {
        display: block;
        color: #ee2c3c;
      }
    }
    .summ-list {
      display: flex;
      margin: 70px 0 0;
      li {
        flex: 1 0 auto;
        position: relative;
        padding: 306px 0 0;
        text-align: center;
        .tit {
          font-weight: bold;
          @include montserrat;
          font-size: 50px;
          line-height: 64px;
          color: #a0a0a0;
        }
        .desc {
          margin: 40px 0 0;
          font-size: 18px;
          line-height: 30px;
          letter-spacing: -0.03em;
          color: #000;
        }
        &::before {
          display: block;
          content: "";
          position: absolute;
          top: 118px;
          left: -12px;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: #ee2c3c;
        }
        &:first-child {
          background: url(../../assets/images/about/about_sensibility.webp) center top no-repeat;
          &::before {
            display: none;
          }
        }
        &:last-child {
          background: url(../../assets/images/about/about_sens.webp) center top no-repeat;
          .tit {
            color: #ee2c3c;
          }
        }
      }
    }
    .solution-list {
      display: flex;
      margin: 98px 0 0;
      ul {
        flex: 1 0 auto;
        li {
          @include montserrat;
          font-weight: 700;
          font-size: 40px;
          line-height: 70px;
          color: #000;
        }
      }
    }
    .txt {
      @include montserrat;
      margin: 65px 0 0;
      border-top: 1px solid #b7b7b7;
      padding: 80px 0 0;
      font-size: 0;
      text-align: center;
    }
  }
  .introduction {
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .lottie-cont {
      width: 100%;
      margin-top: 150px;
      &.lottie01 {
        z-index: 2;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 260px;
        width: 850px;
        height: 850px;
        margin: 0;
        .lottie-container {
          height: 100%;
        }
      }
    }
    .group {
      .section-tit {
        margin-top: 250px;
      }
      .desc {
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
        letter-spacing: -0.05em;
      }
      &.group-01 {
        position: relative;
        z-index: 3;
        .section-tit {
          margin-top: 20px;
        }
        > .desc {
          margin-top: 0;
        }
      }
    }
    .video {
      z-index: 3;
      position: relative;
      display: flex;
      justify-content: center;
      padding-top: 200px;
      margin-top: 450px;
      background: black;
      color: white;
      min-width: 1264px;
      width: 100%;
      height: 1080px;
      &-title {
        width: 1224px;
        z-index: 10;
        p {
          font-size: 70px;
          line-height: 70px;
          font-family: Montserrat, "Noto Sans KR", sans-serif;
          font-weight: bold;
          & + p {
            margin-top: 30px;
          }
        }
      }

      &-bg {
        video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 1;
        }
      }
    }

    .group {
      & > .desc {
        margin-top: 30px;
      }
    }

    .group-02,
    .group-03 {
      .list {
        margin-top: 100px;
        li {
          display: flex;
          border-top: 1px solid #ddd;
          padding-top: 50px;

          & + li {
            margin-top: 100px;
          }

          strong {
            position: relative;
            flex-basis: 50%;
            height: 34px;
            font-size: 24px;
            line-height: 34px;
            font-weight: bold;
            font-family: Montserrat, "Noto Sans KR", sans-serif;
            letter-spacing: -0.4px;
            padding-left: 27px;

            &::before {
              content: "";
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              position: absolute;
              width: 12px;
              height: 12px;
              background-color: $red;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .group-02 {
      margin-top: 1000px;
      .lottie-02 {
        margin-top: 150px;
        height: 600px;
      }
      .section-tit {
        margin-top: 0;
      }
    }

    .group-03 {
      .desc {
        span {
          display: block;
          margin-bottom: 10px;
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
        }
      }
      .lottie-03 {
        margin-top: 150px;
        min-height: 780px;
      }
    }

    .group-04 {
      .figure-list {
        display: flex;
        justify-content: space-between;
        margin-top: 100px;
        li {
          flex-basis: calc((100% - 120px) / 3);
          padding-top: 50px;
          border-top: 1px solid #ddd;

          strong {
            font-size: 70px;
            line-height: 70px;
            font-weight: bold;
            font-family: Montserrat, "Noto Sans KR", sans-serif;
            letter-spacing: -0.05em;
          }

          &:nth-child(2) strong {
            position: relative;
            em {
              position: absolute;
              margin-top: 7px;
              left: calc(100% + 5px);
              font-size: 24px;
              line-height: 24px;
              font-weight: bold;
              letter-spacing: -0.05em;
            }
          }

          span {
            display: block;
            margin-top: 10px;
            font-size: 24px;
            line-height: 34px;
            color: #a0a0a0;
            letter-spacing: -0.05em;
          }
        }
      }
    }

    .images {
      margin-top: 150px;
      width: 100%;
      min-width: 1264px;
      .top {
        position: relative;
        p {
          position: absolute;
          bottom: 40px;
          left: 50%;
          transform: translateX(-50%);
          width: 1224px;
          font-size: 14px;
          line-height: 14px;
          font-weight: 400;
          text-align: left;
          color: #000;
          @include montserrat();
        }
      }
      .image {
        background-color: #ddd;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        .image {
          flex-basis: calc((100% - 10px) / 2);
        }
      }
    }

    .group-05 {
      .lottie-05 {
        height: 600px;
        margin-top: 50px;
      }
    }
  }
  .work-list {
    display: flex;
    min-width: $globalWidth;
    flex-wrap: wrap;
    margin: 160px 0 0;
    padding: 0 80px 160px;
    box-sizing: border-box;
    li {
      width: calc((100% - 120px) / 3);
      margin: 120px 0 0 60px;
      &:nth-child(3n + 1) {
        margin-left: 0;
      }
      &:nth-child(-n + 3) {
        margin-top: 0;
      }
      .thumb {
        overflow: hidden;
        img {
          width: 100%;
          transition: all 0.5s ease-in-out;
        }
      }
      .info {
        position: relative;
        margin: 30px 0 0;
        padding: 28px 0 0;
        .circle {
          position: absolute;
          top: 0;
          left: 0;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: #ee2c3c;
        }
        .title {
          .name {
            margin-bottom: 10px;
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
            letter-spacing: -0.05em;
            color: #000;
          }
          .prize {
            color: #a0a0a0;
            font-size: 14px;
            line-height: 24px;
          }
        }
        .more {
          @include montserrat;
          display: inline-block;
          position: relative;
          margin: 25px 0 0;
          //padding: 0 6px 0 0;
          font-size: 14px;
          line-height: 20px;
          transition: all 0.5s ease-in-out;
          color: #000;
          &::before {
            display: block;
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            height: 1px;
            background: #000;
            transition: all 0.3s ease-in-out;
          }
          &::after {
            display: block;
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: -1;
            width: 12px;
            height: 1px;
            background: #ee2c3c;
            transition: all 0.3s ease-in-out;
          }
        }
      }
      &:hover,
      &:focus {
        .thumb {
          img {
            transform: scale(1.1);
          }
        }
        .info {
          .more {
            color: #ee2c3c;
            &::before {
              background: #ee2c3c;
            }
          }
        }
      }
    }
  }
  .award {
    min-width: $globalWidth;
    .section-tit {
      @include aboutWidth;
    }
    &-list {
      margin-top: 115px;
      &:first-child {
        margin-top: 188px;
      }
      .year-title {
        @include aboutWidth;
        @include montserrat;
        font-size: 120px;
        font-weight: 900;
        color: $red;
      }
    }
    & + .contents {
      .section-tit {
        padding-top: 171px;
      }
    }
    .recent {
      position: relative;
      @include clearFix();
      @include aboutWidth;
      &-swiper-list {
        overflow: visible;
        position: static;
        float: right;
        margin-top: -110px;
        width: 50%;
        li {
          position: relative;
          overflow: hidden;
          border-radius: 100%;
          .thumb {
            display: block;
            width: 580px;
            img {
              vertical-align: top;
            }
          }
          .title {
            position: absolute;
            top: 96px;
            left: 90px;
            font-size: 48px;
            line-height: 62px;
            font-weight: bold;
            color: #fff;
            letter-spacing: -0.05em;
            ::v-deep span {
              @include montserrat();
            }
          }
        }
        .swiper-pagination {
          position: absolute;
          top: 95px;
          bottom: auto;
          left: 20px;
          width: calc(50% - 20px);
          ::v-deep .swiper-pagination-bullet {
            display: block;
            width: auto;
            height: auto;
            background: transparent;
            border-radius: 0;
            font-size: 22px;
            font-weight: bold;
            line-height: 32px;
            text-align: left;
            color: #000;
            transition: color 0.3s ease;
            overflow: hidden;
            opacity: 1;
            letter-spacing: -0.05em;
            & + .swiper-pagination-bullet {
              margin-top: 29px;
            }
            &-active {
              color: $red;
            }
            .title {
              float: left;
              display: block;
              width: 140px;
            }
            .text {
              float: left;
              display: block;
            }
          }
        }
      }
    }
    .previous {
      position: relative;
      margin-top: 43px;
      overflow: hidden;
      &:before {
        content: "";
        position: absolute;
        top: 42px;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background: #dddbdb;
      }
      .previous-swiper {
        overflow: visible;
        @include aboutWidth;
        .swiper-wrapper {
          .swiper-slide {
            width: 300px;
          }
          .title {
            @include montserrat();
            font-size: 32px;
            color: #a0a0a0;
            &.active {
              font-weight: 900;
              color: $red;
            }
          }
          .list {
            margin-top: 37px;
            &:before {
              content: "";
              display: block;
              margin-bottom: 20px;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background: #ee2c3c;
            }
            li {
              font-size: 14px;
              line-height: 20px;
              font-weight: bold;
              word-break: break-word;
              letter-spacing: -0.05em;
              + li {
                margin-top: 7px;
              }
              &.active {
                font-weight: bold;
                color: $red;
              }
            }
          }
        }
      }
    }
  }
</style>
